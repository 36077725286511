/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */
import { useMutation, useQuery, useSuspenseQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import type {
  AcceptLatestAgreement200,
  CustomerResponse,
  DeleteCustomer422,
  GetCommuneSignInUrl200,
  GetCustomerIsRegistered200One,
  GetCustomerIsRegistered200Two,
  GetCustomerIsRegisteredParams,
  GetCustomerMiles200,
  GetCustomerMilesParams,
  GetCustomerOrderAndDeliveryStatus200,
  GetCustomerOrderAndDeliveryStatusParams,
  GetCustomerOrderDetail200,
  GetCustomerOrderDetailParams,
  GetCustomerOrders200,
  GetCustomerOrdersParams,
  GetCustomerOrdersStampsProducts200,
  GetCustomerOrdersSummery200,
  GetCustomerPenaltyPayments200,
  GetCustomerPenaltyPaymentsParams,
  GetCustomerPointDetail200,
  GetCustomerPointDetailParams,
  GetCustomerPoints200,
  GetCustomerPointsParams,
  GetInviteCode200,
  GetMyPageSummary200,
  GetMypageLink200,
  GetPointMile200,
  GetPointProduct200,
  GetPointProductParams,
  GetPoints200,
  Login200,
  Login422,
  LoginBodyOne,
  LoginBodyTwo,
  LoginCheckout200,
  LoginCheckoutBodyOne,
  LoginCheckoutBodyTwo,
  LoginCommune200,
  LoginCommune422,
  LoginCommuneBodyOne,
  LoginCommuneBodyTwo,
  LoginLineAt200,
  LoginLineAt422,
  LoginLineAtBodyOne,
  LoginLineAtBodyTwo,
  LoginLog200,
  MileResponse,
  Mypage200,
  MypageBodyOne,
  MypageBodyTwo,
  PostPointProductConfirm200,
  PostPointProductConfirmBody,
  ResetPassword200,
  ResetPassword400,
  ResetPasswordBodyOne,
  ResetPasswordBodyTwo,
  SendPasswordResetMailBodyOne,
  SendPasswordResetMailBodyTwo,
  SignupEmail200,
  SignupEmail422,
  SignupEmailBodyOne,
  SignupEmailBodyTwo,
  SignupPassword200,
  SignupPassword422,
  SignupPasswordBodyOne,
  SignupPasswordBodyTwo,
  UpdateCustomerEmail200,
  UpdateCustomerEmail400,
  UpdateCustomerEmail422,
  UpdateCustomerEmailBody,
  UpdateCustomerShippingAddress200,
  UpdateCustomerShippingAddressBody,
} from ".././schemas";
import { customAxiosInstance } from "../../../configs/customAxiosInstance";
import type { ErrorType } from "../../../configs/customAxiosInstance";
import { customFormUrlEncodedFn } from "../../../configs/formUrlEncoded";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary check if login or not
 */
export const getCustomer = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<CustomerResponse>(
    { url: `/customer`, method: "GET", signal },
    options
  );
};

export const getGetCustomerQueryKey = () => {
  return [`/customer`] as const;
};

export const getGetCustomerQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomer>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomer>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomer>>> = ({ signal }) =>
    getCustomer(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomer>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerQueryResult = NonNullable<Awaited<ReturnType<typeof getCustomer>>>;
export type GetCustomerQueryError = ErrorType<unknown>;

/**
 * @summary check if login or not
 */
export const useGetCustomer = <
  TData = Awaited<ReturnType<typeof getCustomer>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomer>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary check if login or not
 */
export const prefetchGetCustomer = async <
  TData = Awaited<ReturnType<typeof getCustomer>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomer>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCustomerQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetCustomerSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomer>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomer>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomer>>> = ({ signal }) =>
    getCustomer(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getCustomer>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getCustomer>>>;
export type GetCustomerSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary check if login or not
 */
export const useGetCustomerSuspense = <
  TData = Awaited<ReturnType<typeof getCustomer>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomer>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary check if login or not
 */
export const prefetchGetCustomerSuspense = async <
  TData = Awaited<ReturnType<typeof getCustomer>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomer>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCustomerSuspenseQueryOptions(options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary delete customer
 */
export const deleteCustomer = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<void>({ url: `/customer`, method: "DELETE" }, options);
};

export const getDeleteCustomerMutationOptions = <
  TError = ErrorType<DeleteCustomer422>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteCustomer>>, TError, void, TContext>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteCustomer>>, TError, void, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCustomer>>, void> = () => {
    return deleteCustomer(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCustomerMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCustomer>>>;

export type DeleteCustomerMutationError = ErrorType<DeleteCustomer422>;

/**
 * @summary delete customer
 */
export const useDeleteCustomer = <
  TError = ErrorType<DeleteCustomer422>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteCustomer>>, TError, void, TContext>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getDeleteCustomerMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary check customer registered
 */
export const getCustomerIsRegistered = (
  params: GetCustomerIsRegisteredParams,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<GetCustomerIsRegistered200One | GetCustomerIsRegistered200Two>(
    { url: `/customer/is_registered`, method: "POST", params },
    options
  );
};

export const getGetCustomerIsRegisteredMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getCustomerIsRegistered>>,
    TError,
    { params: GetCustomerIsRegisteredParams },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getCustomerIsRegistered>>,
  TError,
  { params: GetCustomerIsRegisteredParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getCustomerIsRegistered>>,
    { params: GetCustomerIsRegisteredParams }
  > = (props) => {
    const { params } = props ?? {};

    return getCustomerIsRegistered(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetCustomerIsRegisteredMutationResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerIsRegistered>>
>;

export type GetCustomerIsRegisteredMutationError = ErrorType<unknown>;

/**
 * @summary check customer registered
 */
export const useGetCustomerIsRegistered = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getCustomerIsRegistered>>,
    TError,
    { params: GetCustomerIsRegisteredParams },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getGetCustomerIsRegisteredMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary send password reset mail
 */
export const sendPasswordResetMail = (
  sendPasswordResetMailBody: SendPasswordResetMailBodyOne | SendPasswordResetMailBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<void>(
    { url: `/customer/send_password_reset_mail`, method: "POST", data: sendPasswordResetMailBody },
    options
  );
};

export const getSendPasswordResetMailMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendPasswordResetMail>>,
    TError,
    { data: SendPasswordResetMailBodyOne | SendPasswordResetMailBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof sendPasswordResetMail>>,
  TError,
  { data: SendPasswordResetMailBodyOne | SendPasswordResetMailBodyTwo },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendPasswordResetMail>>,
    { data: SendPasswordResetMailBodyOne | SendPasswordResetMailBodyTwo }
  > = (props) => {
    const { data } = props ?? {};

    return sendPasswordResetMail(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SendPasswordResetMailMutationResult = NonNullable<
  Awaited<ReturnType<typeof sendPasswordResetMail>>
>;
export type SendPasswordResetMailMutationBody =
  | SendPasswordResetMailBodyOne
  | SendPasswordResetMailBodyTwo;
export type SendPasswordResetMailMutationError = ErrorType<unknown>;

/**
 * @summary send password reset mail
 */
export const useSendPasswordResetMail = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendPasswordResetMail>>,
    TError,
    { data: SendPasswordResetMailBodyOne | SendPasswordResetMailBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getSendPasswordResetMailMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary signup email
 */
export const signupEmail = (
  signupEmailBody: SignupEmailBodyOne | SignupEmailBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<SignupEmail200>(
    { url: `/customer/signup`, method: "POST", data: signupEmailBody },
    options
  );
};

export const getSignupEmailMutationOptions = <
  TError = ErrorType<SignupEmail422>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof signupEmail>>,
    TError,
    { data: SignupEmailBodyOne | SignupEmailBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof signupEmail>>,
  TError,
  { data: SignupEmailBodyOne | SignupEmailBodyTwo },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof signupEmail>>,
    { data: SignupEmailBodyOne | SignupEmailBodyTwo }
  > = (props) => {
    const { data } = props ?? {};

    return signupEmail(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SignupEmailMutationResult = NonNullable<Awaited<ReturnType<typeof signupEmail>>>;
export type SignupEmailMutationBody = SignupEmailBodyOne | SignupEmailBodyTwo;
export type SignupEmailMutationError = ErrorType<SignupEmail422>;

/**
 * @summary signup email
 */
export const useSignupEmail = <TError = ErrorType<SignupEmail422>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof signupEmail>>,
    TError,
    { data: SignupEmailBodyOne | SignupEmailBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getSignupEmailMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary signup password
 */
export const signupPassword = (
  signupPasswordBody: SignupPasswordBodyOne | SignupPasswordBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<SignupPassword200>(
    { url: `/customer/signup/password`, method: "POST", data: signupPasswordBody },
    options
  );
};

export const getSignupPasswordMutationOptions = <
  TError = ErrorType<SignupPassword422>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof signupPassword>>,
    TError,
    { data: SignupPasswordBodyOne | SignupPasswordBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof signupPassword>>,
  TError,
  { data: SignupPasswordBodyOne | SignupPasswordBodyTwo },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof signupPassword>>,
    { data: SignupPasswordBodyOne | SignupPasswordBodyTwo }
  > = (props) => {
    const { data } = props ?? {};

    return signupPassword(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SignupPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof signupPassword>>>;
export type SignupPasswordMutationBody = SignupPasswordBodyOne | SignupPasswordBodyTwo;
export type SignupPasswordMutationError = ErrorType<SignupPassword422>;

/**
 * @summary signup password
 */
export const useSignupPassword = <
  TError = ErrorType<SignupPassword422>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof signupPassword>>,
    TError,
    { data: SignupPasswordBodyOne | SignupPasswordBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getSignupPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary reset password
 */
export const resetPassword = (
  resetPasswordBody: ResetPasswordBodyOne | ResetPasswordBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<ResetPassword200>(
    { url: `/customer/reset_password`, method: "POST", data: resetPasswordBody },
    options
  );
};

export const getResetPasswordMutationOptions = <
  TError = ErrorType<ResetPassword400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetPassword>>,
    TError,
    { data: ResetPasswordBodyOne | ResetPasswordBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof resetPassword>>,
  TError,
  { data: ResetPasswordBodyOne | ResetPasswordBodyTwo },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resetPassword>>,
    { data: ResetPasswordBodyOne | ResetPasswordBodyTwo }
  > = (props) => {
    const { data } = props ?? {};

    return resetPassword(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResetPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof resetPassword>>>;
export type ResetPasswordMutationBody = ResetPasswordBodyOne | ResetPasswordBodyTwo;
export type ResetPasswordMutationError = ErrorType<ResetPassword400>;

/**
 * @summary reset password
 */
export const useResetPassword = <
  TError = ErrorType<ResetPassword400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetPassword>>,
    TError,
    { data: ResetPasswordBodyOne | ResetPasswordBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getResetPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary update customer email
 */
export const updateCustomerEmail = (
  updateCustomerEmailBody: UpdateCustomerEmailBody,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<UpdateCustomerEmail200>(
    {
      url: `/customer/email`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateCustomerEmailBody,
    },
    options
  );
};

export const getUpdateCustomerEmailMutationOptions = <
  TError = ErrorType<UpdateCustomerEmail400 | UpdateCustomerEmail422>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCustomerEmail>>,
    TError,
    { data: UpdateCustomerEmailBody },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateCustomerEmail>>,
  TError,
  { data: UpdateCustomerEmailBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCustomerEmail>>,
    { data: UpdateCustomerEmailBody }
  > = (props) => {
    const { data } = props ?? {};

    return updateCustomerEmail(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateCustomerEmailMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCustomerEmail>>
>;
export type UpdateCustomerEmailMutationBody = UpdateCustomerEmailBody;
export type UpdateCustomerEmailMutationError = ErrorType<
  UpdateCustomerEmail400 | UpdateCustomerEmail422
>;

/**
 * @summary update customer email
 */
export const useUpdateCustomerEmail = <
  TError = ErrorType<UpdateCustomerEmail400 | UpdateCustomerEmail422>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCustomerEmail>>,
    TError,
    { data: UpdateCustomerEmailBody },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getUpdateCustomerEmailMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary update customer shipping_address
 */
export const updateCustomerShippingAddress = (
  updateCustomerShippingAddressBody: UpdateCustomerShippingAddressBody,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<UpdateCustomerShippingAddress200>(
    {
      url: `/customer/shipping_address`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateCustomerShippingAddressBody,
    },
    options
  );
};

export const getUpdateCustomerShippingAddressMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCustomerShippingAddress>>,
    TError,
    { data: UpdateCustomerShippingAddressBody },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateCustomerShippingAddress>>,
  TError,
  { data: UpdateCustomerShippingAddressBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCustomerShippingAddress>>,
    { data: UpdateCustomerShippingAddressBody }
  > = (props) => {
    const { data } = props ?? {};

    return updateCustomerShippingAddress(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateCustomerShippingAddressMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCustomerShippingAddress>>
>;
export type UpdateCustomerShippingAddressMutationBody = UpdateCustomerShippingAddressBody;
export type UpdateCustomerShippingAddressMutationError = ErrorType<unknown>;

/**
 * @summary update customer shipping_address
 */
export const useUpdateCustomerShippingAddress = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCustomerShippingAddress>>,
    TError,
    { data: UpdateCustomerShippingAddressBody },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getUpdateCustomerShippingAddressMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary get customer orders summary
 */
export const getCustomerOrdersSummery = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetCustomerOrdersSummery200>(
    { url: `/customer/orders/summary`, method: "GET", signal },
    options
  );
};

export const getGetCustomerOrdersSummeryQueryKey = () => {
  return [`/customer/orders/summary`] as const;
};

export const getGetCustomerOrdersSummeryQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerOrdersSummery>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCustomerOrdersSummery>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerOrdersSummeryQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomerOrdersSummery>>> = ({
    signal,
  }) => getCustomerOrdersSummery(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerOrdersSummery>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerOrdersSummeryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerOrdersSummery>>
>;
export type GetCustomerOrdersSummeryQueryError = ErrorType<unknown>;

/**
 * @summary get customer orders summary
 */
export const useGetCustomerOrdersSummery = <
  TData = Awaited<ReturnType<typeof getCustomerOrdersSummery>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCustomerOrdersSummery>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerOrdersSummeryQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get customer orders summary
 */
export const prefetchGetCustomerOrdersSummery = async <
  TData = Awaited<ReturnType<typeof getCustomerOrdersSummery>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCustomerOrdersSummery>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCustomerOrdersSummeryQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetCustomerOrdersSummerySuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerOrdersSummery>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomerOrdersSummery>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerOrdersSummeryQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomerOrdersSummery>>> = ({
    signal,
  }) => getCustomerOrdersSummery(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getCustomerOrdersSummery>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerOrdersSummerySuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerOrdersSummery>>
>;
export type GetCustomerOrdersSummerySuspenseQueryError = ErrorType<unknown>;

/**
 * @summary get customer orders summary
 */
export const useGetCustomerOrdersSummerySuspense = <
  TData = Awaited<ReturnType<typeof getCustomerOrdersSummery>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomerOrdersSummery>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerOrdersSummerySuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get customer orders summary
 */
export const prefetchGetCustomerOrdersSummerySuspense = async <
  TData = Awaited<ReturnType<typeof getCustomerOrdersSummery>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomerOrdersSummery>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCustomerOrdersSummerySuspenseQueryOptions(options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary get customer miles
 */
export const getCustomerMiles = (
  params: GetCustomerMilesParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetCustomerMiles200>(
    { url: `/customer/miles`, method: "GET", params, signal },
    options
  );
};

export const getGetCustomerMilesQueryKey = (params: GetCustomerMilesParams) => {
  return [`/customer/miles`, ...(params ? [params] : [])] as const;
};

export const getGetCustomerMilesQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerMiles>>,
  TError = ErrorType<unknown>,
>(
  params: GetCustomerMilesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomerMiles>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerMilesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomerMiles>>> = ({ signal }) =>
    getCustomerMiles(params, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerMiles>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerMilesQueryResult = NonNullable<Awaited<ReturnType<typeof getCustomerMiles>>>;
export type GetCustomerMilesQueryError = ErrorType<unknown>;

/**
 * @summary get customer miles
 */
export const useGetCustomerMiles = <
  TData = Awaited<ReturnType<typeof getCustomerMiles>>,
  TError = ErrorType<unknown>,
>(
  params: GetCustomerMilesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomerMiles>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerMilesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get customer miles
 */
export const prefetchGetCustomerMiles = async <
  TData = Awaited<ReturnType<typeof getCustomerMiles>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params: GetCustomerMilesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomerMiles>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCustomerMilesQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetCustomerMilesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerMiles>>,
  TError = ErrorType<unknown>,
>(
  params: GetCustomerMilesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomerMiles>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerMilesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomerMiles>>> = ({ signal }) =>
    getCustomerMiles(params, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getCustomerMiles>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerMilesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerMiles>>
>;
export type GetCustomerMilesSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary get customer miles
 */
export const useGetCustomerMilesSuspense = <
  TData = Awaited<ReturnType<typeof getCustomerMiles>>,
  TError = ErrorType<unknown>,
>(
  params: GetCustomerMilesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomerMiles>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerMilesSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get customer miles
 */
export const prefetchGetCustomerMilesSuspense = async <
  TData = Awaited<ReturnType<typeof getCustomerMiles>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params: GetCustomerMilesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomerMiles>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCustomerMilesSuspenseQueryOptions(params, options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary get customer stamp product
 */
export const getCustomerOrdersStampsProducts = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetCustomerOrdersStampsProducts200>(
    { url: `/customer/orders/stamps/products`, method: "GET", signal },
    options
  );
};

export const getGetCustomerOrdersStampsProductsQueryKey = () => {
  return [`/customer/orders/stamps/products`] as const;
};

export const getGetCustomerOrdersStampsProductsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerOrdersStampsProducts>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCustomerOrdersStampsProducts>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerOrdersStampsProductsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomerOrdersStampsProducts>>> = ({
    signal,
  }) => getCustomerOrdersStampsProducts(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerOrdersStampsProducts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerOrdersStampsProductsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerOrdersStampsProducts>>
>;
export type GetCustomerOrdersStampsProductsQueryError = ErrorType<unknown>;

/**
 * @summary get customer stamp product
 */
export const useGetCustomerOrdersStampsProducts = <
  TData = Awaited<ReturnType<typeof getCustomerOrdersStampsProducts>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCustomerOrdersStampsProducts>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerOrdersStampsProductsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get customer stamp product
 */
export const prefetchGetCustomerOrdersStampsProducts = async <
  TData = Awaited<ReturnType<typeof getCustomerOrdersStampsProducts>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCustomerOrdersStampsProducts>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCustomerOrdersStampsProductsQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetCustomerOrdersStampsProductsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerOrdersStampsProducts>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getCustomerOrdersStampsProducts>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerOrdersStampsProductsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomerOrdersStampsProducts>>> = ({
    signal,
  }) => getCustomerOrdersStampsProducts(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getCustomerOrdersStampsProducts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerOrdersStampsProductsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerOrdersStampsProducts>>
>;
export type GetCustomerOrdersStampsProductsSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary get customer stamp product
 */
export const useGetCustomerOrdersStampsProductsSuspense = <
  TData = Awaited<ReturnType<typeof getCustomerOrdersStampsProducts>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getCustomerOrdersStampsProducts>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerOrdersStampsProductsSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get customer stamp product
 */
export const prefetchGetCustomerOrdersStampsProductsSuspense = async <
  TData = Awaited<ReturnType<typeof getCustomerOrdersStampsProducts>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getCustomerOrdersStampsProducts>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCustomerOrdersStampsProductsSuspenseQueryOptions(options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary 継続割引プラン未達成時のペナルティ請求の履歴情報
 */
export const getCustomerPenaltyPayments = (
  params: GetCustomerPenaltyPaymentsParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetCustomerPenaltyPayments200>(
    { url: `/customer/penalty_payments`, method: "GET", params, signal },
    options
  );
};

export const getGetCustomerPenaltyPaymentsQueryKey = (params: GetCustomerPenaltyPaymentsParams) => {
  return [`/customer/penalty_payments`, ...(params ? [params] : [])] as const;
};

export const getGetCustomerPenaltyPaymentsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerPenaltyPayments>>,
  TError = ErrorType<unknown>,
>(
  params: GetCustomerPenaltyPaymentsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCustomerPenaltyPayments>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerPenaltyPaymentsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomerPenaltyPayments>>> = ({
    signal,
  }) => getCustomerPenaltyPayments(params, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerPenaltyPayments>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerPenaltyPaymentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerPenaltyPayments>>
>;
export type GetCustomerPenaltyPaymentsQueryError = ErrorType<unknown>;

/**
 * @summary 継続割引プラン未達成時のペナルティ請求の履歴情報
 */
export const useGetCustomerPenaltyPayments = <
  TData = Awaited<ReturnType<typeof getCustomerPenaltyPayments>>,
  TError = ErrorType<unknown>,
>(
  params: GetCustomerPenaltyPaymentsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCustomerPenaltyPayments>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerPenaltyPaymentsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 継続割引プラン未達成時のペナルティ請求の履歴情報
 */
export const prefetchGetCustomerPenaltyPayments = async <
  TData = Awaited<ReturnType<typeof getCustomerPenaltyPayments>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params: GetCustomerPenaltyPaymentsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCustomerPenaltyPayments>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCustomerPenaltyPaymentsQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetCustomerPenaltyPaymentsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerPenaltyPayments>>,
  TError = ErrorType<unknown>,
>(
  params: GetCustomerPenaltyPaymentsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomerPenaltyPayments>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerPenaltyPaymentsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomerPenaltyPayments>>> = ({
    signal,
  }) => getCustomerPenaltyPayments(params, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getCustomerPenaltyPayments>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerPenaltyPaymentsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerPenaltyPayments>>
>;
export type GetCustomerPenaltyPaymentsSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary 継続割引プラン未達成時のペナルティ請求の履歴情報
 */
export const useGetCustomerPenaltyPaymentsSuspense = <
  TData = Awaited<ReturnType<typeof getCustomerPenaltyPayments>>,
  TError = ErrorType<unknown>,
>(
  params: GetCustomerPenaltyPaymentsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomerPenaltyPayments>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerPenaltyPaymentsSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 継続割引プラン未達成時のペナルティ請求の履歴情報
 */
export const prefetchGetCustomerPenaltyPaymentsSuspense = async <
  TData = Awaited<ReturnType<typeof getCustomerPenaltyPayments>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params: GetCustomerPenaltyPaymentsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomerPenaltyPayments>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCustomerPenaltyPaymentsSuspenseQueryOptions(params, options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary customer login
 */
export const mypage = (
  mypageBody: MypageBodyOne | MypageBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<Mypage200>(
    { url: `/mypage`, method: "POST", data: mypageBody },
    options
  );
};

export const getMypageMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof mypage>>,
    TError,
    { data: MypageBodyOne | MypageBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof mypage>>,
  TError,
  { data: MypageBodyOne | MypageBodyTwo },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof mypage>>,
    { data: MypageBodyOne | MypageBodyTwo }
  > = (props) => {
    const { data } = props ?? {};

    return mypage(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type MypageMutationResult = NonNullable<Awaited<ReturnType<typeof mypage>>>;
export type MypageMutationBody = MypageBodyOne | MypageBodyTwo;
export type MypageMutationError = ErrorType<unknown>;

/**
 * @summary customer login
 */
export const useMypage = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof mypage>>,
    TError,
    { data: MypageBodyOne | MypageBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getMypageMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary get mypage summary
 */
export const getMyPageSummary = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetMyPageSummary200>(
    { url: `/mypage/summary`, method: "GET", signal },
    options
  );
};

export const getGetMyPageSummaryQueryKey = () => {
  return [`/mypage/summary`] as const;
};

export const getGetMyPageSummaryQueryOptions = <
  TData = Awaited<ReturnType<typeof getMyPageSummary>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMyPageSummary>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMyPageSummaryQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMyPageSummary>>> = ({ signal }) =>
    getMyPageSummary(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMyPageSummary>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMyPageSummaryQueryResult = NonNullable<Awaited<ReturnType<typeof getMyPageSummary>>>;
export type GetMyPageSummaryQueryError = ErrorType<unknown>;

/**
 * @summary get mypage summary
 */
export const useGetMyPageSummary = <
  TData = Awaited<ReturnType<typeof getMyPageSummary>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMyPageSummary>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMyPageSummaryQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get mypage summary
 */
export const prefetchGetMyPageSummary = async <
  TData = Awaited<ReturnType<typeof getMyPageSummary>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMyPageSummary>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetMyPageSummaryQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetMyPageSummarySuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getMyPageSummary>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMyPageSummary>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMyPageSummaryQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMyPageSummary>>> = ({ signal }) =>
    getMyPageSummary(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getMyPageSummary>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMyPageSummarySuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMyPageSummary>>
>;
export type GetMyPageSummarySuspenseQueryError = ErrorType<unknown>;

/**
 * @summary get mypage summary
 */
export const useGetMyPageSummarySuspense = <
  TData = Awaited<ReturnType<typeof getMyPageSummary>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMyPageSummary>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMyPageSummarySuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get mypage summary
 */
export const prefetchGetMyPageSummarySuspense = async <
  TData = Awaited<ReturnType<typeof getMyPageSummary>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMyPageSummary>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetMyPageSummarySuspenseQueryOptions(options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

export const getMyPageSummaryImageName = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<string>(
    { url: `/mypage/summary_image_name`, method: "GET", signal },
    options
  );
};

export const getGetMyPageSummaryImageNameQueryKey = () => {
  return [`/mypage/summary_image_name`] as const;
};

export const getGetMyPageSummaryImageNameQueryOptions = <
  TData = Awaited<ReturnType<typeof getMyPageSummaryImageName>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getMyPageSummaryImageName>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMyPageSummaryImageNameQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMyPageSummaryImageName>>> = ({
    signal,
  }) => getMyPageSummaryImageName(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMyPageSummaryImageName>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMyPageSummaryImageNameQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMyPageSummaryImageName>>
>;
export type GetMyPageSummaryImageNameQueryError = ErrorType<unknown>;

export const useGetMyPageSummaryImageName = <
  TData = Awaited<ReturnType<typeof getMyPageSummaryImageName>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getMyPageSummaryImageName>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMyPageSummaryImageNameQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchGetMyPageSummaryImageName = async <
  TData = Awaited<ReturnType<typeof getMyPageSummaryImageName>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getMyPageSummaryImageName>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetMyPageSummaryImageNameQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetMyPageSummaryImageNameSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getMyPageSummaryImageName>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMyPageSummaryImageName>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMyPageSummaryImageNameQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMyPageSummaryImageName>>> = ({
    signal,
  }) => getMyPageSummaryImageName(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getMyPageSummaryImageName>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMyPageSummaryImageNameSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMyPageSummaryImageName>>
>;
export type GetMyPageSummaryImageNameSuspenseQueryError = ErrorType<unknown>;

export const useGetMyPageSummaryImageNameSuspense = <
  TData = Awaited<ReturnType<typeof getMyPageSummaryImageName>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMyPageSummaryImageName>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMyPageSummaryImageNameSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchGetMyPageSummaryImageNameSuspense = async <
  TData = Awaited<ReturnType<typeof getMyPageSummaryImageName>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMyPageSummaryImageName>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetMyPageSummaryImageNameSuspenseQueryOptions(options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary customer login checkout
 */
export const loginCheckout = (
  loginCheckoutBody: LoginCheckoutBodyOne | LoginCheckoutBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<LoginCheckout200>(
    { url: `/loginCheckout`, method: "POST", data: loginCheckoutBody },
    options
  );
};

export const getLoginCheckoutMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof loginCheckout>>,
    TError,
    { data: LoginCheckoutBodyOne | LoginCheckoutBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof loginCheckout>>,
  TError,
  { data: LoginCheckoutBodyOne | LoginCheckoutBodyTwo },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof loginCheckout>>,
    { data: LoginCheckoutBodyOne | LoginCheckoutBodyTwo }
  > = (props) => {
    const { data } = props ?? {};

    return loginCheckout(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LoginCheckoutMutationResult = NonNullable<Awaited<ReturnType<typeof loginCheckout>>>;
export type LoginCheckoutMutationBody = LoginCheckoutBodyOne | LoginCheckoutBodyTwo;
export type LoginCheckoutMutationError = ErrorType<unknown>;

/**
 * @summary customer login checkout
 */
export const useLoginCheckout = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof loginCheckout>>,
    TError,
    { data: LoginCheckoutBodyOne | LoginCheckoutBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getLoginCheckoutMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary customer get commune login url
 */
export const getCommuneSignInUrl = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetCommuneSignInUrl200>(
    { url: `/commune/signin/url`, method: "GET", signal },
    options
  );
};

export const getGetCommuneSignInUrlQueryKey = () => {
  return [`/commune/signin/url`] as const;
};

export const getGetCommuneSignInUrlQueryOptions = <
  TData = Awaited<ReturnType<typeof getCommuneSignInUrl>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCommuneSignInUrl>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCommuneSignInUrlQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCommuneSignInUrl>>> = ({ signal }) =>
    getCommuneSignInUrl(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCommuneSignInUrl>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCommuneSignInUrlQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCommuneSignInUrl>>
>;
export type GetCommuneSignInUrlQueryError = ErrorType<unknown>;

/**
 * @summary customer get commune login url
 */
export const useGetCommuneSignInUrl = <
  TData = Awaited<ReturnType<typeof getCommuneSignInUrl>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCommuneSignInUrl>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCommuneSignInUrlQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary customer get commune login url
 */
export const prefetchGetCommuneSignInUrl = async <
  TData = Awaited<ReturnType<typeof getCommuneSignInUrl>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCommuneSignInUrl>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCommuneSignInUrlQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetCommuneSignInUrlSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getCommuneSignInUrl>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCommuneSignInUrl>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCommuneSignInUrlQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCommuneSignInUrl>>> = ({ signal }) =>
    getCommuneSignInUrl(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getCommuneSignInUrl>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCommuneSignInUrlSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCommuneSignInUrl>>
>;
export type GetCommuneSignInUrlSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary customer get commune login url
 */
export const useGetCommuneSignInUrlSuspense = <
  TData = Awaited<ReturnType<typeof getCommuneSignInUrl>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCommuneSignInUrl>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCommuneSignInUrlSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary customer get commune login url
 */
export const prefetchGetCommuneSignInUrlSuspense = async <
  TData = Awaited<ReturnType<typeof getCommuneSignInUrl>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCommuneSignInUrl>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCommuneSignInUrlSuspenseQueryOptions(options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary customer login commune
 */
export const loginCommune = (
  loginCommuneBody: LoginCommuneBodyOne | LoginCommuneBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<LoginCommune200>(
    { url: `/loginCommune`, method: "POST", data: loginCommuneBody },
    options
  );
};

export const getLoginCommuneMutationOptions = <
  TError = ErrorType<LoginCommune422>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof loginCommune>>,
    TError,
    { data: LoginCommuneBodyOne | LoginCommuneBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof loginCommune>>,
  TError,
  { data: LoginCommuneBodyOne | LoginCommuneBodyTwo },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof loginCommune>>,
    { data: LoginCommuneBodyOne | LoginCommuneBodyTwo }
  > = (props) => {
    const { data } = props ?? {};

    return loginCommune(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LoginCommuneMutationResult = NonNullable<Awaited<ReturnType<typeof loginCommune>>>;
export type LoginCommuneMutationBody = LoginCommuneBodyOne | LoginCommuneBodyTwo;
export type LoginCommuneMutationError = ErrorType<LoginCommune422>;

/**
 * @summary customer login commune
 */
export const useLoginCommune = <TError = ErrorType<LoginCommune422>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof loginCommune>>,
    TError,
    { data: LoginCommuneBodyOne | LoginCommuneBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getLoginCommuneMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary customer login
 */
export const login = (
  loginBody: LoginBodyOne | LoginBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<Login200>({ url: `/login`, method: "POST", data: loginBody }, options);
};

export const getLoginMutationOptions = <
  TError = ErrorType<Login422>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof login>>,
    TError,
    { data: LoginBodyOne | LoginBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof login>>,
  TError,
  { data: LoginBodyOne | LoginBodyTwo },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof login>>,
    { data: LoginBodyOne | LoginBodyTwo }
  > = (props) => {
    const { data } = props ?? {};

    return login(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LoginMutationResult = NonNullable<Awaited<ReturnType<typeof login>>>;
export type LoginMutationBody = LoginBodyOne | LoginBodyTwo;
export type LoginMutationError = ErrorType<Login422>;

/**
 * @summary customer login
 */
export const useLogin = <TError = ErrorType<Login422>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof login>>,
    TError,
    { data: LoginBodyOne | LoginBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getLoginMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary customer login lineAt
 */
export const loginLineAt = (
  loginLineAtBody: LoginLineAtBodyOne | LoginLineAtBodyTwo,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<LoginLineAt200>(
    { url: `/loginLineAt`, method: "POST", data: loginLineAtBody },
    options
  );
};

export const getLoginLineAtMutationOptions = <
  TError = ErrorType<LoginLineAt422>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof loginLineAt>>,
    TError,
    { data: LoginLineAtBodyOne | LoginLineAtBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof loginLineAt>>,
  TError,
  { data: LoginLineAtBodyOne | LoginLineAtBodyTwo },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof loginLineAt>>,
    { data: LoginLineAtBodyOne | LoginLineAtBodyTwo }
  > = (props) => {
    const { data } = props ?? {};

    return loginLineAt(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LoginLineAtMutationResult = NonNullable<Awaited<ReturnType<typeof loginLineAt>>>;
export type LoginLineAtMutationBody = LoginLineAtBodyOne | LoginLineAtBodyTwo;
export type LoginLineAtMutationError = ErrorType<LoginLineAt422>;

/**
 * @summary customer login lineAt
 */
export const useLoginLineAt = <TError = ErrorType<LoginLineAt422>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof loginLineAt>>,
    TError,
    { data: LoginLineAtBodyOne | LoginLineAtBodyTwo },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getLoginLineAtMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary customer logout
 */
export const logout = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<void>({ url: `/logout`, method: "POST" }, options);
};

export const getLogoutMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof logout>>, TError, void, TContext>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof logout>>, TError, void, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof logout>>, void> = () => {
    return logout(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LogoutMutationResult = NonNullable<Awaited<ReturnType<typeof logout>>>;

export type LogoutMutationError = ErrorType<unknown>;

/**
 * @summary customer logout
 */
export const useLogout = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof logout>>, TError, void, TContext>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getLogoutMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary get points
 */
export const getPoints = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetPoints200>({ url: `/points`, method: "GET", signal }, options);
};

export const getGetPointsQueryKey = () => {
  return [`/points`] as const;
};

export const getGetPointsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPoints>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPoints>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPointsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPoints>>> = ({ signal }) =>
    getPoints(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPoints>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPointsQueryResult = NonNullable<Awaited<ReturnType<typeof getPoints>>>;
export type GetPointsQueryError = ErrorType<unknown>;

/**
 * @summary get points
 */
export const useGetPoints = <
  TData = Awaited<ReturnType<typeof getPoints>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPoints>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPointsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get points
 */
export const prefetchGetPoints = async <
  TData = Awaited<ReturnType<typeof getPoints>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPoints>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetPointsQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetPointsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getPoints>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPoints>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPointsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPoints>>> = ({ signal }) =>
    getPoints(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getPoints>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPointsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getPoints>>>;
export type GetPointsSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary get points
 */
export const useGetPointsSuspense = <
  TData = Awaited<ReturnType<typeof getPoints>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPoints>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPointsSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get points
 */
export const prefetchGetPointsSuspense = async <
  TData = Awaited<ReturnType<typeof getPoints>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPoints>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetPointsSuspenseQueryOptions(options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary get miles
 */
export const getMiles = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<MileResponse>({ url: `/miles`, method: "GET", signal }, options);
};

export const getGetMilesQueryKey = () => {
  return [`/miles`] as const;
};

export const getGetMilesQueryOptions = <
  TData = Awaited<ReturnType<typeof getMiles>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMiles>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMilesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMiles>>> = ({ signal }) =>
    getMiles(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMiles>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMilesQueryResult = NonNullable<Awaited<ReturnType<typeof getMiles>>>;
export type GetMilesQueryError = ErrorType<unknown>;

/**
 * @summary get miles
 */
export const useGetMiles = <
  TData = Awaited<ReturnType<typeof getMiles>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMiles>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMilesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get miles
 */
export const prefetchGetMiles = async <
  TData = Awaited<ReturnType<typeof getMiles>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMiles>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetMilesQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetMilesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getMiles>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMiles>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMilesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMiles>>> = ({ signal }) =>
    getMiles(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getMiles>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMilesSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getMiles>>>;
export type GetMilesSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary get miles
 */
export const useGetMilesSuspense = <
  TData = Awaited<ReturnType<typeof getMiles>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMiles>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMilesSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get miles
 */
export const prefetchGetMilesSuspense = async <
  TData = Awaited<ReturnType<typeof getMiles>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMiles>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetMilesSuspenseQueryOptions(options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary get mypage link
 */
export const getMypageLink = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetMypageLink200>(
    { url: `/miles/mypageLink`, method: "GET", signal },
    options
  );
};

export const getGetMypageLinkQueryKey = () => {
  return [`/miles/mypageLink`] as const;
};

export const getGetMypageLinkQueryOptions = <
  TData = Awaited<ReturnType<typeof getMypageLink>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMypageLink>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMypageLinkQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMypageLink>>> = ({ signal }) =>
    getMypageLink(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMypageLink>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMypageLinkQueryResult = NonNullable<Awaited<ReturnType<typeof getMypageLink>>>;
export type GetMypageLinkQueryError = ErrorType<unknown>;

/**
 * @summary get mypage link
 */
export const useGetMypageLink = <
  TData = Awaited<ReturnType<typeof getMypageLink>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMypageLink>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMypageLinkQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get mypage link
 */
export const prefetchGetMypageLink = async <
  TData = Awaited<ReturnType<typeof getMypageLink>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMypageLink>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetMypageLinkQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetMypageLinkSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getMypageLink>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMypageLink>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMypageLinkQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMypageLink>>> = ({ signal }) =>
    getMypageLink(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getMypageLink>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMypageLinkSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMypageLink>>
>;
export type GetMypageLinkSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary get mypage link
 */
export const useGetMypageLinkSuspense = <
  TData = Awaited<ReturnType<typeof getMypageLink>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMypageLink>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMypageLinkSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get mypage link
 */
export const prefetchGetMypageLinkSuspense = async <
  TData = Awaited<ReturnType<typeof getMypageLink>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMypageLink>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetMypageLinkSuspenseQueryOptions(options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary get point mile
 */
export const getPointMile = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetPointMile200>(
    { url: `/point_mile`, method: "GET", signal },
    options
  );
};

export const getGetPointMileQueryKey = () => {
  return [`/point_mile`] as const;
};

export const getGetPointMileQueryOptions = <
  TData = Awaited<ReturnType<typeof getPointMile>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPointMile>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPointMileQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPointMile>>> = ({ signal }) =>
    getPointMile(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPointMile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPointMileQueryResult = NonNullable<Awaited<ReturnType<typeof getPointMile>>>;
export type GetPointMileQueryError = ErrorType<unknown>;

/**
 * @summary get point mile
 */
export const useGetPointMile = <
  TData = Awaited<ReturnType<typeof getPointMile>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPointMile>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPointMileQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get point mile
 */
export const prefetchGetPointMile = async <
  TData = Awaited<ReturnType<typeof getPointMile>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPointMile>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetPointMileQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetPointMileSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getPointMile>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPointMile>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPointMileQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPointMile>>> = ({ signal }) =>
    getPointMile(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getPointMile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPointMileSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getPointMile>>>;
export type GetPointMileSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary get point mile
 */
export const useGetPointMileSuspense = <
  TData = Awaited<ReturnType<typeof getPointMile>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPointMile>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPointMileSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get point mile
 */
export const prefetchGetPointMileSuspense = async <
  TData = Awaited<ReturnType<typeof getPointMile>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPointMile>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetPointMileSuspenseQueryOptions(options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary get invite code
 */
export const getInviteCode = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetInviteCode200>(
    { url: `/invites/code`, method: "GET", signal },
    options
  );
};

export const getGetInviteCodeQueryKey = () => {
  return [`/invites/code`] as const;
};

export const getGetInviteCodeQueryOptions = <
  TData = Awaited<ReturnType<typeof getInviteCode>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getInviteCode>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInviteCodeQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInviteCode>>> = ({ signal }) =>
    getInviteCode(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getInviteCode>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetInviteCodeQueryResult = NonNullable<Awaited<ReturnType<typeof getInviteCode>>>;
export type GetInviteCodeQueryError = ErrorType<unknown>;

/**
 * @summary get invite code
 */
export const useGetInviteCode = <
  TData = Awaited<ReturnType<typeof getInviteCode>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getInviteCode>>, TError, TData>>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetInviteCodeQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get invite code
 */
export const prefetchGetInviteCode = async <
  TData = Awaited<ReturnType<typeof getInviteCode>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getInviteCode>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetInviteCodeQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetInviteCodeSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getInviteCode>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getInviteCode>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInviteCodeQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInviteCode>>> = ({ signal }) =>
    getInviteCode(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getInviteCode>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetInviteCodeSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInviteCode>>
>;
export type GetInviteCodeSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary get invite code
 */
export const useGetInviteCodeSuspense = <
  TData = Awaited<ReturnType<typeof getInviteCode>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof getInviteCode>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetInviteCodeSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get invite code
 */
export const prefetchGetInviteCodeSuspense = async <
  TData = Awaited<ReturnType<typeof getInviteCode>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getInviteCode>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetInviteCodeSuspenseQueryOptions(options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary get customer orders
 */
export const getCustomerOrders = (
  params: GetCustomerOrdersParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetCustomerOrders200>(
    { url: `/customer/orders`, method: "GET", params, signal },
    options
  );
};

export const getGetCustomerOrdersQueryKey = (params: GetCustomerOrdersParams) => {
  return [`/customer/orders`, ...(params ? [params] : [])] as const;
};

export const getGetCustomerOrdersQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerOrders>>,
  TError = ErrorType<unknown>,
>(
  params: GetCustomerOrdersParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomerOrders>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerOrdersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomerOrders>>> = ({ signal }) =>
    getCustomerOrders(params, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerOrders>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerOrdersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerOrders>>
>;
export type GetCustomerOrdersQueryError = ErrorType<unknown>;

/**
 * @summary get customer orders
 */
export const useGetCustomerOrders = <
  TData = Awaited<ReturnType<typeof getCustomerOrders>>,
  TError = ErrorType<unknown>,
>(
  params: GetCustomerOrdersParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomerOrders>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerOrdersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get customer orders
 */
export const prefetchGetCustomerOrders = async <
  TData = Awaited<ReturnType<typeof getCustomerOrders>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params: GetCustomerOrdersParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomerOrders>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCustomerOrdersQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetCustomerOrdersSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerOrders>>,
  TError = ErrorType<unknown>,
>(
  params: GetCustomerOrdersParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomerOrders>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerOrdersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomerOrders>>> = ({ signal }) =>
    getCustomerOrders(params, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getCustomerOrders>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerOrdersSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerOrders>>
>;
export type GetCustomerOrdersSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary get customer orders
 */
export const useGetCustomerOrdersSuspense = <
  TData = Awaited<ReturnType<typeof getCustomerOrders>>,
  TError = ErrorType<unknown>,
>(
  params: GetCustomerOrdersParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomerOrders>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerOrdersSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get customer orders
 */
export const prefetchGetCustomerOrdersSuspense = async <
  TData = Awaited<ReturnType<typeof getCustomerOrders>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params: GetCustomerOrdersParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomerOrders>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCustomerOrdersSuspenseQueryOptions(params, options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary get customer order detail
 */
export const getCustomerOrderDetail = (
  params: GetCustomerOrderDetailParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetCustomerOrderDetail200>(
    { url: `/customer/order_detail`, method: "GET", params, signal },
    options
  );
};

export const getGetCustomerOrderDetailQueryKey = (params: GetCustomerOrderDetailParams) => {
  return [`/customer/order_detail`, ...(params ? [params] : [])] as const;
};

export const getGetCustomerOrderDetailQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerOrderDetail>>,
  TError = ErrorType<unknown>,
>(
  params: GetCustomerOrderDetailParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCustomerOrderDetail>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerOrderDetailQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomerOrderDetail>>> = ({ signal }) =>
    getCustomerOrderDetail(params, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerOrderDetail>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerOrderDetailQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerOrderDetail>>
>;
export type GetCustomerOrderDetailQueryError = ErrorType<unknown>;

/**
 * @summary get customer order detail
 */
export const useGetCustomerOrderDetail = <
  TData = Awaited<ReturnType<typeof getCustomerOrderDetail>>,
  TError = ErrorType<unknown>,
>(
  params: GetCustomerOrderDetailParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCustomerOrderDetail>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerOrderDetailQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get customer order detail
 */
export const prefetchGetCustomerOrderDetail = async <
  TData = Awaited<ReturnType<typeof getCustomerOrderDetail>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params: GetCustomerOrderDetailParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCustomerOrderDetail>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCustomerOrderDetailQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetCustomerOrderDetailSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerOrderDetail>>,
  TError = ErrorType<unknown>,
>(
  params: GetCustomerOrderDetailParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomerOrderDetail>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerOrderDetailQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomerOrderDetail>>> = ({ signal }) =>
    getCustomerOrderDetail(params, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getCustomerOrderDetail>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerOrderDetailSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerOrderDetail>>
>;
export type GetCustomerOrderDetailSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary get customer order detail
 */
export const useGetCustomerOrderDetailSuspense = <
  TData = Awaited<ReturnType<typeof getCustomerOrderDetail>>,
  TError = ErrorType<unknown>,
>(
  params: GetCustomerOrderDetailParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomerOrderDetail>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerOrderDetailSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get customer order detail
 */
export const prefetchGetCustomerOrderDetailSuspense = async <
  TData = Awaited<ReturnType<typeof getCustomerOrderDetail>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params: GetCustomerOrderDetailParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomerOrderDetail>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCustomerOrderDetailSuspenseQueryOptions(params, options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary get customer order and delivery status
 */
export const getCustomerOrderAndDeliveryStatus = (
  params: GetCustomerOrderAndDeliveryStatusParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetCustomerOrderAndDeliveryStatus200>(
    { url: `/customer/order_and_delivery_status`, method: "GET", params, signal },
    options
  );
};

export const getGetCustomerOrderAndDeliveryStatusQueryKey = (
  params: GetCustomerOrderAndDeliveryStatusParams
) => {
  return [`/customer/order_and_delivery_status`, ...(params ? [params] : [])] as const;
};

export const getGetCustomerOrderAndDeliveryStatusQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerOrderAndDeliveryStatus>>,
  TError = ErrorType<unknown>,
>(
  params: GetCustomerOrderAndDeliveryStatusParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCustomerOrderAndDeliveryStatus>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerOrderAndDeliveryStatusQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomerOrderAndDeliveryStatus>>> = ({
    signal,
  }) => getCustomerOrderAndDeliveryStatus(params, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerOrderAndDeliveryStatus>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerOrderAndDeliveryStatusQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerOrderAndDeliveryStatus>>
>;
export type GetCustomerOrderAndDeliveryStatusQueryError = ErrorType<unknown>;

/**
 * @summary get customer order and delivery status
 */
export const useGetCustomerOrderAndDeliveryStatus = <
  TData = Awaited<ReturnType<typeof getCustomerOrderAndDeliveryStatus>>,
  TError = ErrorType<unknown>,
>(
  params: GetCustomerOrderAndDeliveryStatusParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCustomerOrderAndDeliveryStatus>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerOrderAndDeliveryStatusQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get customer order and delivery status
 */
export const prefetchGetCustomerOrderAndDeliveryStatus = async <
  TData = Awaited<ReturnType<typeof getCustomerOrderAndDeliveryStatus>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params: GetCustomerOrderAndDeliveryStatusParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCustomerOrderAndDeliveryStatus>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCustomerOrderAndDeliveryStatusQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetCustomerOrderAndDeliveryStatusSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerOrderAndDeliveryStatus>>,
  TError = ErrorType<unknown>,
>(
  params: GetCustomerOrderAndDeliveryStatusParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getCustomerOrderAndDeliveryStatus>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerOrderAndDeliveryStatusQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomerOrderAndDeliveryStatus>>> = ({
    signal,
  }) => getCustomerOrderAndDeliveryStatus(params, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getCustomerOrderAndDeliveryStatus>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerOrderAndDeliveryStatusSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerOrderAndDeliveryStatus>>
>;
export type GetCustomerOrderAndDeliveryStatusSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary get customer order and delivery status
 */
export const useGetCustomerOrderAndDeliveryStatusSuspense = <
  TData = Awaited<ReturnType<typeof getCustomerOrderAndDeliveryStatus>>,
  TError = ErrorType<unknown>,
>(
  params: GetCustomerOrderAndDeliveryStatusParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getCustomerOrderAndDeliveryStatus>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerOrderAndDeliveryStatusSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get customer order and delivery status
 */
export const prefetchGetCustomerOrderAndDeliveryStatusSuspense = async <
  TData = Awaited<ReturnType<typeof getCustomerOrderAndDeliveryStatus>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params: GetCustomerOrderAndDeliveryStatusParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getCustomerOrderAndDeliveryStatus>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCustomerOrderAndDeliveryStatusSuspenseQueryOptions(params, options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary post login log
 */
export const loginLog = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<LoginLog200>({ url: `/post_login_log`, method: "POST" }, options);
};

export const getLoginLogMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof loginLog>>, TError, void, TContext>;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof loginLog>>, TError, void, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof loginLog>>, void> = () => {
    return loginLog(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LoginLogMutationResult = NonNullable<Awaited<ReturnType<typeof loginLog>>>;

export type LoginLogMutationError = ErrorType<unknown>;

/**
 * @summary post login log
 */
export const useLoginLog = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof loginLog>>, TError, void, TContext>;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getLoginLogMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary accept Latest Agreement
 */
export const acceptLatestAgreement = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<AcceptLatestAgreement200>(
    { url: `/acceptLatestAgreement`, method: "POST" },
    options
  );
};

export const getAcceptLatestAgreementMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof acceptLatestAgreement>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof acceptLatestAgreement>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof acceptLatestAgreement>>,
    void
  > = () => {
    return acceptLatestAgreement(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AcceptLatestAgreementMutationResult = NonNullable<
  Awaited<ReturnType<typeof acceptLatestAgreement>>
>;

export type AcceptLatestAgreementMutationError = ErrorType<unknown>;

/**
 * @summary accept Latest Agreement
 */
export const useAcceptLatestAgreement = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof acceptLatestAgreement>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getAcceptLatestAgreementMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary get customer points
 */
export const getCustomerPoints = (
  params: GetCustomerPointsParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetCustomerPoints200>(
    { url: `/customer/points`, method: "GET", params, signal },
    options
  );
};

export const getGetCustomerPointsQueryKey = (params: GetCustomerPointsParams) => {
  return [`/customer/points`, ...(params ? [params] : [])] as const;
};

export const getGetCustomerPointsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerPoints>>,
  TError = ErrorType<unknown>,
>(
  params: GetCustomerPointsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomerPoints>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerPointsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomerPoints>>> = ({ signal }) =>
    getCustomerPoints(params, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerPoints>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerPointsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerPoints>>
>;
export type GetCustomerPointsQueryError = ErrorType<unknown>;

/**
 * @summary get customer points
 */
export const useGetCustomerPoints = <
  TData = Awaited<ReturnType<typeof getCustomerPoints>>,
  TError = ErrorType<unknown>,
>(
  params: GetCustomerPointsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomerPoints>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerPointsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get customer points
 */
export const prefetchGetCustomerPoints = async <
  TData = Awaited<ReturnType<typeof getCustomerPoints>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params: GetCustomerPointsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomerPoints>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCustomerPointsQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetCustomerPointsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerPoints>>,
  TError = ErrorType<unknown>,
>(
  params: GetCustomerPointsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomerPoints>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerPointsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomerPoints>>> = ({ signal }) =>
    getCustomerPoints(params, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getCustomerPoints>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerPointsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerPoints>>
>;
export type GetCustomerPointsSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary get customer points
 */
export const useGetCustomerPointsSuspense = <
  TData = Awaited<ReturnType<typeof getCustomerPoints>>,
  TError = ErrorType<unknown>,
>(
  params: GetCustomerPointsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomerPoints>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerPointsSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get customer points
 */
export const prefetchGetCustomerPointsSuspense = async <
  TData = Awaited<ReturnType<typeof getCustomerPoints>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params: GetCustomerPointsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomerPoints>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCustomerPointsSuspenseQueryOptions(params, options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary get customer order point
 */
export const getCustomerPointDetail = (
  params: GetCustomerPointDetailParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetCustomerPointDetail200>(
    { url: `/customer/point_detail`, method: "GET", params, signal },
    options
  );
};

export const getGetCustomerPointDetailQueryKey = (params: GetCustomerPointDetailParams) => {
  return [`/customer/point_detail`, ...(params ? [params] : [])] as const;
};

export const getGetCustomerPointDetailQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerPointDetail>>,
  TError = ErrorType<unknown>,
>(
  params: GetCustomerPointDetailParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCustomerPointDetail>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerPointDetailQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomerPointDetail>>> = ({ signal }) =>
    getCustomerPointDetail(params, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerPointDetail>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerPointDetailQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerPointDetail>>
>;
export type GetCustomerPointDetailQueryError = ErrorType<unknown>;

/**
 * @summary get customer order point
 */
export const useGetCustomerPointDetail = <
  TData = Awaited<ReturnType<typeof getCustomerPointDetail>>,
  TError = ErrorType<unknown>,
>(
  params: GetCustomerPointDetailParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCustomerPointDetail>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerPointDetailQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get customer order point
 */
export const prefetchGetCustomerPointDetail = async <
  TData = Awaited<ReturnType<typeof getCustomerPointDetail>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params: GetCustomerPointDetailParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCustomerPointDetail>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCustomerPointDetailQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetCustomerPointDetailSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerPointDetail>>,
  TError = ErrorType<unknown>,
>(
  params: GetCustomerPointDetailParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomerPointDetail>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerPointDetailQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomerPointDetail>>> = ({ signal }) =>
    getCustomerPointDetail(params, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getCustomerPointDetail>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerPointDetailSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerPointDetail>>
>;
export type GetCustomerPointDetailSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary get customer order point
 */
export const useGetCustomerPointDetailSuspense = <
  TData = Awaited<ReturnType<typeof getCustomerPointDetail>>,
  TError = ErrorType<unknown>,
>(
  params: GetCustomerPointDetailParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomerPointDetail>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerPointDetailSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get customer order point
 */
export const prefetchGetCustomerPointDetailSuspense = async <
  TData = Awaited<ReturnType<typeof getCustomerPointDetail>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params: GetCustomerPointDetailParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomerPointDetail>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetCustomerPointDetailSuspenseQueryOptions(params, options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary get point product
 */
export const getPointProduct = (
  params: GetPointProductParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetPointProduct200>(
    { url: `/pointCart/fetchProduct`, method: "GET", params, signal },
    options
  );
};

export const getGetPointProductQueryKey = (params: GetPointProductParams) => {
  return [`/pointCart/fetchProduct`, ...(params ? [params] : [])] as const;
};

export const getGetPointProductQueryOptions = <
  TData = Awaited<ReturnType<typeof getPointProduct>>,
  TError = ErrorType<unknown>,
>(
  params: GetPointProductParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPointProduct>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPointProductQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPointProduct>>> = ({ signal }) =>
    getPointProduct(params, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPointProduct>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPointProductQueryResult = NonNullable<Awaited<ReturnType<typeof getPointProduct>>>;
export type GetPointProductQueryError = ErrorType<unknown>;

/**
 * @summary get point product
 */
export const useGetPointProduct = <
  TData = Awaited<ReturnType<typeof getPointProduct>>,
  TError = ErrorType<unknown>,
>(
  params: GetPointProductParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPointProduct>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPointProductQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get point product
 */
export const prefetchGetPointProduct = async <
  TData = Awaited<ReturnType<typeof getPointProduct>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params: GetPointProductParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPointProduct>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetPointProductQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetPointProductSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getPointProduct>>,
  TError = ErrorType<unknown>,
>(
  params: GetPointProductParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPointProduct>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPointProductQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPointProduct>>> = ({ signal }) =>
    getPointProduct(params, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getPointProduct>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPointProductSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPointProduct>>
>;
export type GetPointProductSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary get point product
 */
export const useGetPointProductSuspense = <
  TData = Awaited<ReturnType<typeof getPointProduct>>,
  TError = ErrorType<unknown>,
>(
  params: GetPointProductParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPointProduct>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPointProductSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get point product
 */
export const prefetchGetPointProductSuspense = async <
  TData = Awaited<ReturnType<typeof getPointProduct>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params: GetPointProductParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPointProduct>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetPointProductSuspenseQueryOptions(params, options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};

/**
 * @summary post point product confirm
 */
export const postPointProductConfirm = (
  postPointProductConfirmBody: PostPointProductConfirmBody,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<PostPointProductConfirm200>(
    {
      url: `/pointCart/confirm`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: postPointProductConfirmBody,
    },
    options
  );
};

export const getPostPointProductConfirmMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPointProductConfirm>>,
    TError,
    { data: PostPointProductConfirmBody },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPointProductConfirm>>,
  TError,
  { data: PostPointProductConfirmBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPointProductConfirm>>,
    { data: PostPointProductConfirmBody }
  > = (props) => {
    const { data } = props ?? {};

    return postPointProductConfirm(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPointProductConfirmMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPointProductConfirm>>
>;
export type PostPointProductConfirmMutationBody = PostPointProductConfirmBody;
export type PostPointProductConfirmMutationError = ErrorType<unknown>;

/**
 * @summary post point product confirm
 */
export const usePostPointProductConfirm = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPointProductConfirm>>,
    TError,
    { data: PostPointProductConfirmBody },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getPostPointProductConfirmMutationOptions(options);

  return useMutation(mutationOptions);
};
