export const PRODUCT_SALE_PARAMETER = {
  firstDiscountRate: 0.8,
  secondDiscountRate: 0.9,
  shippingFeeNormal: 500,
  shippingFeeFrozen: 900,
  // 招待割引金額
  inviteDiscount: -2000,
  // 配送できる最低限の金額--常温
  minDeliveryAmountNormal: 2600,
  // 配送できる最低限の金額--冷凍
  minDeliveryAmountFrozen: 2600,
};

export const SYS_PARAMETER = {
  unsubscribePresentCouponId: "KYKSTP",
};

export const FEATURE_FLAG = {
  isFreeShippingCampaignActive: false,
  isExtraGiftCampaignActive: false,
  isShoppingNewItemsActive: false,
  isSpecialGiftCampaignActive: true,
};

export const OKIHAI_MAX_PRICE = 150000;

export const SYS_COOKIE = {
  salusAppTokenKey: "salus_app_token",
  salusAppTokenValue: "app_ios_webview",
  sessionHashKey: "payse_session_hash",
};

export const MILE_RANK_PARAMETER = {
  rankUpPromotionPriceDiff: 10000, // ランクによる購入を促すメッセージを表示する最大の金額差
};

export const ERROR_CODE = {
  /**
   * https://base-food.sentry.io/issues/6085970289/events/?project=1489748
   * TypeError /app/Entities/Cart.php in App\Entities\Cart::build
   */
  emailTypeError: "6085970289",
};
