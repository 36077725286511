import { z } from "zod";

import { ValidationError } from "./error";

const inputDataSchema = z.object({
  email: z
    .string({ required_error: "メールアドレスが未入力です" })
    .min(1, "メールアドレスの形式が正しくありません")
    .email("メールアドレスの形式が正しくありません"),
});

type InputData = z.infer<typeof inputDataSchema>;

/**
 * フォームの入力値を検証する。失敗した場合はエラーをスローする
 */
export const validateFormInputs = <T extends InputData>(inputs: Partial<T>) => {
  const validationResult = inputDataSchema.safeParse(inputs);
  if (!validationResult.success) {
    const errors = validationResult.error.errors.flatMap((e) => e.message);

    if (errors.length > 0) {
      throw new ValidationError(errors[0]);
    }
  }
};
