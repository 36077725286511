import { ValueOf } from "next/dist/shared/lib/constants";
import { ImageProps } from "next/image";

import { DiscountPlanName, ProductPrice } from "@/generated/open-api/schemas";

import {
  breadCategoryProductNames,
  pastaCategoryProductNames,
  cookieCategoryProductNames,
  sauceCategoryProductNames,
  deliPastaCategoryProductNames,
  deliYakisobaCategoryProductNames,
  setCategoryProductNames,
  pancakeCategoryProductNames,
  mealBreadCategoryProductNames,
  sweetBreadCategoryProductNames,
  savoryBreadCategoryProductNames,
  outletProductNames,
} from "./consts";

export type ProductModel = {
  id: number;
  name: string;
  title: string;
  variantId: number;
  variantTitle: string;
  price: number;
  normalPrice: number;
  normalTotalPrice: number;
  firstSubscriptionPrice: number;
  firstSubscriptionTotalPrice: number;
  firstSubscriptionPercentage: number;
  afterSecondSubscriptionPrice: number;
  afterSecondSubscriptionTotalPrice: number;
  secondSubscriptionPercentage: number;
  productBrandNewDiscountPrice?: number;
  productBrandNewDiscountTotalPrice?: number;
  productBrandNewDiscountPercentage?: number;
  selectOptions: number[];
  unit: string;
  unitMultiple: number;
  meal: string;
  isFreeze: boolean;
  sku: string;
  subscription: boolean;
  vailSubscription: boolean;
  hasSubscription: boolean;
  outOfStock: boolean;
  quantity: number;
  images: string[];
  variantImageIndex: number;
  tags: string[];
  inventoryQuantity: number;
  shopUrl: string | null;
  royaltyMileNum: number;
  isSuspension: boolean;
  isPreSale: boolean;
  // 期間限定
  isLimited?: boolean;
  isBrandNewDiscount: boolean;
  displayOrder: number;
  salesType: SalesType;
  // フロントエンド拡張
  isOutlet: boolean;
  hiddenOnLp: boolean;
  // フロントエンド拡張--アウトレット商品の場合の最大購入可能数量
  maxQuantity?: number;

  // セット商品用の拡張
  isSetProduct: boolean;
  hiddenAnygift?: boolean;
  ukomiGroupName?: string; // ukomiのグループ名、デフォルトはvariantTitle
  preDiscountPrice?: number; // 値引き前価格
  setProducts?: { variantId: number; quantity: number }[]; // セット商品の中身
  setProductName?: "START_SET_0" | "START_SET_1" | "START_SET_2" | "START_SET_3" | "START_SET_4";
  prices: Partial<Record<DiscountPlanName, ProductPrice>>;
};

export interface ProductImageModel {
  src: ImageProps["src"];
  width?: ImageProps["width"];
  sizes?: ImageProps["sizes"];
}

export interface ProductsBySkuCalcPriceModel {
  name: string;
  defaultPrice: number;
  totalPrice: number;
  secondTotalPrice: number;
  images: ProductImageModel[];
  quantity: number;
  variation: number;
  subTitle?: string;
}

export interface ProductOptionModel {
  name?: string;
  short_name?: string;
  products: { sku: string; quantity: number }[];
  images?: ProductImageModel[];
  sub_title?: string;
  isFreeze?: boolean;
  isLimited?: boolean;
}

export type ProductCategory =
  | "basebread"
  | "basepancake"
  | "basepasta"
  | "basecookies"
  | "basepasta_frozen"
  | "basepasta_frozen_set"
  | "basesauce"
  | "baseyakisoba";

export const productSubCategoryMap = {
  mealBread: mealBreadCategoryProductNames,
  sweetBread: sweetBreadCategoryProductNames,
  savoryBread: savoryBreadCategoryProductNames,
  pancake: pancakeCategoryProductNames,
  pasta: pastaCategoryProductNames,
  cookie: cookieCategoryProductNames,
  sauce: sauceCategoryProductNames,
  deliPasta: deliPastaCategoryProductNames,
  deliYakisoba: deliYakisobaCategoryProductNames,
};

export type SubCategory = keyof typeof productSubCategoryMap;

export type BreadProductName = (typeof breadCategoryProductNames)[number];
export type PastaProductName = (typeof pastaCategoryProductNames)[number];
export type CookieProductName = (typeof cookieCategoryProductNames)[number];
export type SauceProductName = (typeof sauceCategoryProductNames)[number];
export type DeliPastaProductName = (typeof deliPastaCategoryProductNames)[number];
export type DeliYakisobaProductName = (typeof deliYakisobaCategoryProductNames)[number];
export type SetProductName = (typeof setCategoryProductNames)[number];
export type PancakeProductName = (typeof pancakeCategoryProductNames)[number];
export type OutletProductName = (typeof outletProductNames)[number];

export type ProductName =
  | BreadProductName
  | PastaProductName
  | CookieProductName
  | SauceProductName
  | DeliPastaProductName
  | DeliYakisobaProductName
  | SetProductName
  | PancakeProductName
  | OutletProductName;

/**
 * 1:通常商品 2:アウトレット商品
 */
export const SalesType = {
  normal: 1,
  outlet: 2,
} as const;
export type SalesType = ValueOf<typeof SalesType>;

export type ProductTemperature = "normal" | "freeze";
